@font-face {
  font-family: "DBHeaventRoundedv32";
  src: local("DBHeaventRoundedv32"),
    url("./DBHeaventRounded/DBHeaventRoundedv32.woff2")
      format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "DBHeaventRoundedMedv32";
  src: local("DBHeaventRoundedMedv32"),
    url("./DBHeaventRounded-Med/DBHeaventRoundedMedv32.woff2")
      format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Sarabun";
  src: local("Sarabun"),
    url("./Sarabun/Sarabun-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "NotoSansRegular";
  src: local("NotoSansRegular"),
     url("./FontNotoSans/NotoSans_Regular.ttf")

  /*  url("./NotoSansThai/NotoSansThai-Regular.ttf")  */
  format("truetype");
  font-display: swap; 
}


@font-face {
  font-family: "NotoSansRegularThai";
  src: local("NotoSansRegular"),
   
  url("./NotoSansThai/NotoSansThai-Regular.ttf")  
  format("truetype");
  font-display: swap; 
}

@font-face {
  font-family: "NotoSansSemiBold";
  src: local("NotoSansSemiBold"),
    url("./FontNotoSans/NotoSans-SemiBold.ttf") 
    format("truetype");
    font-display: swap; 

}

@font-face {
  font-family: "NotoSansSemiBoldThai";
  src: local("NotoSansSemiBold"),
    url("./NotoSansThai/NotoSansThai-SemiBold.ttf") 
    /* url("./NotoSansThai_ExtraCondensed/NotoSansThai_ExtraCondensed-SemiBold.ttf")  */

    format("truetype");
    font-display: swap; 

}

/*
@font-face {
  font-family: "NotoSans Regular";
  src: local("NotoSansSemiBold"),
    url("./NotoSansThai_SemiCondensed/NotoSansThai_SemiCondensed-Regular.ttf") format("truetype");

}

@font-face {
  font-family: "NotoSans Thin";
  src: local("NotoSansSemiBold"),

} */






/* *,
button,
input,
label {
  font-family: "DBHeaventRoundedv32";
  -webkit-font-smoothing: antialiased;
} */

html,
body {
  font-family: "DBHeaventRounded";
  -webkit-font-smoothing: antialiased;
}
